<template>
  <v-data-table
    :headers="tableHeaders"
    :items="items"
    :options.sync="options"
    :items-per-page="totalItems"
    :server-items-length="totalItems"
    :loading="isLoadingBillOfLading"
    :expanded.sync="expanded"
    loading-text="Loading... Please wait"
    :footer-props="{
      showFirstLastPage: true,
      itemsPerPageOptions: rowsPerPageItems,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
    }"
    @update:options="getDataFromApi"
  >
    <template v-slot:[`item.assignorName`]="{ item }">
      {{ item.assignorId ? item.assignorName : $t("FORM_LABELS.NO_ASSIGNOR") }}
    </template>

    <template v-slot:[`item.locationName`]="{ item }">
      {{ item.locationId ? item.locationName : $t("FORM_LABELS.NO_LOCATION") }}
    </template>

    <template v-slot:[`item.bolDate`]="{ item }">
      {{ item.bolDate | moment("DD.MM.YYYY") }}
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <router-link
        v-if="checkIfUserHasPermission(['protocols-create'])"
        :to="{
          name: 'add-protocols',
          params: {
            bol: item,
          },
        }"
      >
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab color="success" x-small v-bind="attrs" v-on="on">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("LABELS.ADD_NEW") }}</span>
        </v-tooltip>
      </router-link>
    </template>
    <template v-slot:no-results>
      <v-alert :value="true" color="error" icon="warning">
        Your search for "{{ searchQuery }}" found no results.
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import { FETCH_BILL_OF_LADINGS } from "@/modules/bill-of-lading/store/bill-of-lading.module";

export default {
  name: "BillOfLadingTable",
  mixins: [permissionMixin],
  data() {
    return {
      items: [],
      options: {
        sortBy: ["bolDate"],
        sortDesc: [false],
        page: 1,
        length: 5,
        itemsPerPage: 5,
        protocolId: 0,
        query: "",
        hasAssignor: true,
      },
      tableLoading: true,
      expanded: [],
      totalItems: 100,
      rowsPerPageItems: [10, 20, 30, 40, 50],
      tableHeaders: [
        {
          text: this.$i18n.t("MENU.ITEM.ASL.ASSIGNOR"),
          value: "assignorName",
          sortable: false,
        },
        {
          text: this.$i18n.t("MENU.ITEM.ASL.LOCATION"),
          value: "locationName",
          sortable: false,
        },
        {
          text: this.$i18n.t("FORM_LABELS.DATE"),
          value: "bolDate",
        },
        {
          align: "right",
          text: "",
          value: "actions",
          sortable: false,
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["isLoadingBillOfLading"]),
  },
  methods: {
    getDataFromApi() {
      this.options.start =
        this.options.page == 1
          ? 0
          : this.options.itemsPerPage * (this.options.page - 1); //set offset
      let apiParams = this.$url.transformParams(this.options);
      this.$store.dispatch(FETCH_BILL_OF_LADINGS, apiParams).then((data) => {
        this.$nextTick(function() {
          this.items = data.data.items;
          this.totalItems = data.data.totalItemsCount;
        });
      });
    },
  },
};
</script>

<style></style>
