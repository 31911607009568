var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.items,"options":_vm.options,"items-per-page":_vm.totalItems,"server-items-length":_vm.totalItems,"loading":_vm.isLoadingBillOfLading,"expanded":_vm.expanded,"loading-text":"Loading... Please wait","footer-props":{
    showFirstLastPage: true,
    itemsPerPageOptions: _vm.rowsPerPageItems,
    firstIcon: 'mdi-arrow-collapse-left',
    lastIcon: 'mdi-arrow-collapse-right',
  }},on:{"update:options":[function($event){_vm.options=$event},_vm.getDataFromApi],"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.assignorName",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.assignorId ? item.assignorName : _vm.$t("FORM_LABELS.NO_ASSIGNOR"))+" ")]}},{key:"item.locationName",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.locationId ? item.locationName : _vm.$t("FORM_LABELS.NO_LOCATION"))+" ")]}},{key:"item.bolDate",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.bolDate,"DD.MM.YYYY"))+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [(_vm.checkIfUserHasPermission(['bill-of-lading-update']))?_c('router-link',{attrs:{"to":{ name: 'edit-bill-of-lading', params: { id: item.id } }}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","dark":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("LABELS.EDIT")))])])],1):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"warning"}},[_vm._v(" Your search for \""+_vm._s(_vm.searchQuery)+"\" found no results. ")])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }