<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title font-weight-bolder text-dark">
        GPS данни за машини без товарителници
      </h3>
      <div class="card-toolbar">
        <button
          class="btn btn-warning font-weight-bolder mr-2"
          @click="handleExport"
        >
          Експорт
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <SearchTools
        :searchQuery.sync="searchQuery"
        @do-search="doSearch"
        @do-clear="doClear"
        :placeholder="$t('LABELS.SEARCH_BOL')"
        class="p-0 m-0"
      >
        <template v-slot:prepend>
          <b-col class="pb-0" :md="6">
            <b-form-group id="date-from-group" label-for="date-from-input">
              <FormDatepicker
                id="date-to-input"
                type="text"
                name="date_from"
                :i18n="{ placeholder: 'FORM_LABELS.DATE_FROM' }"
                :validateState="{}"
                v-model="filters.dateFrom"
              ></FormDatepicker>
            </b-form-group>
          </b-col>
          <b-col class="pb-0" :md="6">
            <b-form-group id="date-from-group" label-for="date-from-input">
              <FormDatepicker
                id="date-from-input"
                type="text"
                name="date_to"
                :i18n="{ placeholder: 'FORM_LABELS.DATE_TO' }"
                :validateState="{}"
                v-model="filters.dateTo"
              ></FormDatepicker>
            </b-form-group>
          </b-col>
        </template>
      </SearchTools>
      <v-data-table
        :headers="tableHeaders"
        :items="items"
        :options.sync="options"
        :items-per-page="totalItems"
        :server-items-length="totalItems"
        :loading="tableLoading"
        :expanded.sync="expanded"
        loading-text="Loading... Please wait"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageOptions: rowsPerPageItems,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right'
        }"
        @update:options="getDataFromApi"
      >
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | moment("DD.MM.YYYY") }}
        </template>

        <template v-slot:[`item.totalDuration`]="{ item }">
          {{ secondsToTime(item.totalDuration) }}
        </template>

        <template v-slot:[`item.range`]="{ item }">
          {{ item.firstBegin | moment("HH:mm") }} -
          {{ item.lastEnd | moment("HH:mm") }}
        </template>

        <template v-slot:[`item.vehicleId`]="{ item }">
          {{ item.vehicleId }}
          <router-link to="machines/list">
            <v-tooltip :key="item.id" v-if="!item.machine" right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="20" color="error" v-bind="attrs" v-on="on"
                  >mdi-alert-circle</v-icon
                >
              </template>
              <span
                >Не е намерена машина с този GPS номер. Моля намерете я в
                списъка и добавете номера към нея.</span
              >
            </v-tooltip>
          </router-link>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            v-if="
              checkIfUserHasPermission(['bill-of-lading-create']) &&
                item.machine
            "
            :to="{
              name: 'add-bill-of-lading',
              params: {
                formData: {
                  machine: {
                    machinableId: item.machine.id,
                    machinableType: item.machine.expenseType,
                    lastUsedStaffId: item.machine.lastUsedStaffId
                  },
                  staffId: item.machine.lastUsedStaffId,
                  bolDate: item.date,
                  work: [
                    {
                      subcontractorsLocationId: null,
                      note: null,
                      serviceId: null,
                      measureId: null,
                      dateWorked: item.date,
                      serviceQty: null
                    }
                  ]
                }
              }
            }"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn fab color="success" x-small v-bind="attrs" v-on="on">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Добави товарителница</span>
            </v-tooltip>
          </router-link>
        </template>

        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
            Your search for "{{ searchQuery }}" found no results.
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import SearchTools from "@/core/components/table/SearchTools.vue";
import { permissionMixin } from "@/core/mixins/permissionMixin.js";
import {
  FETCH_NO_BOL,
  GENERATE_GPS_NO_BOL_EXCEL_URL
} from "@/core/services/store/gps.module";

export default {
  name: "GpsLogNoBolTable",
  components: {
    SearchTools,
    FormDatepicker
  },
  mixins: [permissionMixin],
  data() {
    return {
      items: [],
      totalItems: 100,
      searchQuery: "",
      options: {
        sortBy: ["date"],
        sortDesc: [false],
        page: 1,
        length: 10,
        itemsPerPage: 10,
        query: "",
        finishedFilter: 0
      },
      filters: {
        dateFrom: null,
        dateTo: null
      },
      tableLoading: true,
      expanded: [],
      rowsPerPageItems: [10, 20, 30, 40, 50],
      tableHeaders: [
        {
          text: "Дата",
          value: "date",
          sortable: false
        },
        {
          text: "Име",
          value: "vehicleName",
          sortable: false
        },
        {
          text: "Регистрационен номер",
          value: "vehiclePlate",
          sortable: false
        },
        {
          text: "Време запален",
          value: "totalDuration",
          sortable: false
        },
        {
          text: "Първа/посл. раб.",
          value: "range",
          sortable: false
        },
        {
          text: "GPS номер",
          value: "vehicleId",
          sortable: false
        },
        {
          align: "right",
          text: "",
          value: "actions",
          sortable: false,
          width: "10%"
        }
      ]
    };
  },
  computed: {
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    }
  },
  methods: {
    getDataFromApi() {
      let params = this.params;

      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      let apiParams = this.$url.transformParams(params);
      this.$store.dispatch(FETCH_NO_BOL, apiParams).then(data => {
        this.$nextTick(function() {
          this.items = data.data.items;
          this.totalItems = data.data.totalItemsCount;
          this.tableLoading = false;
        });
      });
    },
    secondsToTime(seconds) {
      var sec_num = parseInt(seconds, 10); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      return hours + ":" + minutes;
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      Object.keys(vm.filters).forEach(key => {
        vm.filters[key] = null;
      });

      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    handleExport() {
      this.$store.dispatch(
        GENERATE_GPS_NO_BOL_EXCEL_URL,
        this.$url.transformParams({
          ...this.filters,
          query: this.searchQuery
        })
      );
    }
  }
};
</script>

<style></style>
