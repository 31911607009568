<template>
  <div>
    <!--begin::Dashboard-->
    <div class="row">
      <div v-if="hasPermission('bill-of-lading-show')" class="col-lg-6">
        <div class="card card-custom card-stretch">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title font-weight-bolder text-dark">
              Товарителници без протокол
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">
            <BillOfLadingTable></BillOfLadingTable>
          </div>
        </div>
      </div>
      <div v-if="hasPermission('bill-of-lading-show')" class="col-lg-6">
        <div class="card card-custom card-stretch">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title font-weight-bolder text-dark">
              Товарителници без обект
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">
            <BolNoLocationTable></BolNoLocationTable>
          </div>
        </div>
      </div>
      <div v-if="hasPermission('protocols-show')" class="col-lg-6">
        <div class="card card-custom card-stretch">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title font-weight-bolder text-dark">
              Не финализирани протоколи
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">
            <ProtocolsNotFinishedTable></ProtocolsNotFinishedTable>
          </div>
        </div>
      </div>
      <div v-if="hasPermission('protocols-show')" class="col-lg-6">
        <div class="card card-custom card-stretch">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title font-weight-bolder text-dark">
              Не фактурирани протоколи
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-2">
            <ProtocolsNoInvoiceTable></ProtocolsNoInvoiceTable>
          </div>
        </div>
      </div>
      <div v-if="hasPermission('bill-of-lading-show')" class="col-lg-12">
        <GpsLogNoBolTable></GpsLogNoBolTable>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import KTCard from "@/view/content/Card.vue";
import BillOfLadingTable from "./dashboard/BillOfLadingTable";
import BolNoLocationTable from "./dashboard/BolNoLocationTable";
import ProtocolsNotFinishedTable from "./dashboard/ProtocolsNotFinishedTable";
import ProtocolsNoInvoiceTable from "./dashboard/ProtocolsNoInvoiceTable";
import GpsLogNoBolTable from "./dashboard/GpsLogNoBolTable";
import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    KTCard,
    BillOfLadingTable,
    BolNoLocationTable,
    ProtocolsNotFinishedTable,
    ProtocolsNoInvoiceTable,
    GpsLogNoBolTable
  },
  computed: {
    ...mapGetters(["currentUser"]),
    permissions() {
      return this.currentUser.permissions;
    }
  },
  methods: {
    hasPermission(permission) {
      return (
        !this.permissions ||
        (this.permissions &&
          this.permissions.length &&
          this.permissions.some(v => permission === v))
      );
    }
  }
};
</script>
